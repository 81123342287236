import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazily } from 'react-lazily';
import { Outlet, Route, Routes } from 'react-router-dom';
import { routes } from '@/routes/constants';
var Branches = lazily(function () { return import('@/ui/modules/branches/containers/Branches/Branches'); }).Branches;
var CreateBranch = lazily(function () { return import('@/ui/modules/branches/containers/CreateBranch'); }).CreateBranch;
var BranchProfile = lazily(function () { return import('@/ui/modules/branches/containers/BranchProfile'); }).BranchProfile;
var BranchInfo = lazily(function () { return import('@/ui/modules/branches/containers/BranchInfo'); }).BranchInfo;
var BranchModules = lazily(function () { return import('@/ui/modules/branches/containers/BranchModules'); }).BranchModules;
var SearchBranch = lazily(function () { return import('@/ui/modules/branches/containers/SearchBranch'); }).SearchBranch;
export function BranchesRoutes() {
    return (_jsx(Routes, { children: _jsxs(Route, { element: _jsx(Outlet, {}), children: [_jsx(Route, { index: true, element: _jsx(Branches, {}) }), _jsx(Route, { path: routes.search, element: _jsx(SearchBranch, {}) }), _jsx(Route, { path: routes.create, element: _jsx(CreateBranch, {}) }), _jsxs(Route, { path: routes.branchId, element: _jsx(Outlet, {}), children: [_jsx(Route, { index: true, element: _jsx(BranchProfile, {}) }), _jsx(Route, { path: routes.info, element: _jsx(BranchInfo, {}) }), _jsx(Route, { path: routes.modules, element: _jsx(BranchModules, {}) })] })] }) }));
}
