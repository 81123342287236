import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { trackers } from '@/configurations/trackers';
import { useAuth } from '@agentguru/auth';
import { useTranslation } from '@agentguru/i18n';
import { AppAgencyRoute } from '../components/AppAgencyRoute';
import { CockpitRoute } from '../components/CockpitRoute';
import { routes } from '../constants';
export function useBottomNavBarRoutes() {
    var t = useTranslation().t;
    var logout = useAuth().logout;
    var handleLogout = useCallback(function () {
        logout();
        trackers.destroy();
    }, [logout]);
    return useMemo(function () {
        return [
            {
                key: 0,
                path: routes.goToAgencies(),
                icon: 'ag-agency',
                label: t('routes.agencies'),
                disabled: false,
                matches: [routes.agencies],
                isCustomRoute: false,
                subRoutes: [],
            },
            {
                key: 1,
                path: routes.goToBranches(),
                icon: 'ag-branch',
                label: t('routes.branches'),
                disabled: false,
                matches: [routes.branches],
                isCustomRoute: false,
                subRoutes: [],
            },
            {
                key: 2,
                path: routes.goToCommissions(),
                icon: 'ag-commission',
                label: t('routes.agency_commission'),
                disabled: false,
                matches: [routes.commissions],
                isCustomRoute: false,
                subRoutes: [],
            },
            {
                key: 3,
                path: routes.goToServiceFee(),
                icon: 'ag-payment',
                label: t('page.title.edit_service_fee'),
                disabled: false,
                matches: [routes.serviceFee],
                isCustomRoute: false,
                subRoutes: [],
            },
            {
                key: 4,
                path: routes.goToMarkups(),
                icon: 'ag-markup',
                label: t('routes.markup'),
                disabled: false,
                matches: [routes.markup],
                isCustomRoute: false,
                subRoutes: [],
            },
            {
                key: 5,
                path: routes.goToAirlineCommissions(),
                icon: 'ag-airline-commissions',
                label: t('routes.airlines_commissions'),
                disabled: false,
                matches: [routes.airlinesCommissions],
                isCustomRoute: false,
                subRoutes: [],
            },
            {
                key: 6,
                path: routes.goToSearchModules(),
                icon: 'ag-search-module',
                label: t('routes.search'),
                disabled: false,
                matches: [routes.searchModules],
                isCustomRoute: false,
                subRoutes: [],
            },
            {
                key: 7,
                icon: 'ag-profile',
                label: t('routes.profile'),
                disabled: false,
                path: '/',
                matches: [],
                isCustomRoute: true,
                subRoutes: [
                    {
                        key: 0,
                        icon: '',
                        disabled: false,
                        path: '/',
                        label: 'AppAgencyRoute',
                        customRender: _jsx(AppAgencyRoute, {}),
                    },
                    {
                        key: 1,
                        icon: '',
                        disabled: false,
                        path: '/',
                        label: 'CockpitRoute',
                        customRender: _jsx(CockpitRoute, {}),
                    },
                    {
                        key: 2,
                        icon: 'ag-logout',
                        disabled: false,
                        path: '/',
                        label: t('routes.logout'),
                        callback: function () { return handleLogout(); },
                    },
                ],
            },
        ];
    }, [handleLogout, t]);
}
