var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useNavBarRoutes } from '@/routes/hooks';
import { useBreakingPoint, useMediaQuery } from '@agentguru/hooks';
import { MainLayoutNavBar, NavBar } from '@agentguru/layout';
export function AppNavBar(props) {
    var _a = useBreakingPoint(), sm = _a.sm, md = _a.md;
    var activeMediaQuery = useMediaQuery({
        maxWidth: 1300,
    });
    var navBarRoutes = useNavBarRoutes();
    if (sm)
        return null;
    return (_jsx(MainLayoutNavBar, { children: _jsx(NavBar, __assign({ navBarRoutes: navBarRoutes, showCollapsed: md || activeMediaQuery, variant: "controlPanel", showWhatsNew: false }, props)) }));
}
