import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState, createContext, useContext } from 'react';
var ListContext = createContext({
    currentTab: 0,
    setTab: function () { },
});
export function ListContextProvider(_a) {
    var children = _a.children;
    var _b = useState(0), currentTab = _b[0], setTab = _b[1];
    var value = useMemo(function () { return ({
        currentTab: currentTab,
        setTab: setTab,
    }); }, [currentTab]);
    return _jsx(ListContext.Provider, { value: value, children: children });
}
export function useListContext() {
    return useContext(ListContext);
}
