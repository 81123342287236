/* eslint-disable no-console */
import { ENV } from '@/configurations/env';
var Beamer = /** @class */ (function () {
    function Beamer() {
        this.buttonId = 'beamerButton';
        this.scriptId = 'beamer-script';
        this.scriptUrl = 'https://app.getbeamer.com/js/beamer-embed.js';
    }
    // eslint-disable-next-line class-methods-use-this
    Beamer.prototype.init = function () {
        //
    };
    Beamer.prototype.destroy = function () {
        try {
            window.Beamer.destroy();
            var beamerScript = document.getElementById(this.scriptId);
            beamerScript === null || beamerScript === void 0 ? void 0 : beamerScript.remove();
        }
        catch (error) {
            console.log(error);
        }
    };
    Beamer.prototype.identify = function (user) {
        try {
            var existsScript = document.getElementById(this.scriptId);
            if (existsScript)
                return;
            var beamerId = ENV.APP_BEAMER_ID;
            if (!beamerId)
                return;
            window.beamer_config = {
                product_id: beamerId,
                selector: "#".concat(this.buttonId),
                button: false,
                counter: true,
                display: 'none',
                user_id: user.id,
                user_firstname: user.first_name,
                user_lastname: user.last_name,
                user_email: user.email,
                multi_user: true,
            };
            var head = document.getElementsByTagName('head')[0];
            var script = document.createElement('script');
            script.defer = true;
            script.src = this.scriptUrl;
            script.id = this.scriptId;
            head.appendChild(script);
        }
        catch (error) {
            console.log(error);
        }
    };
    Beamer.prototype.getButtonId = function () {
        return this.buttonId;
    };
    return Beamer;
}());
export var beamer = new Beamer();
