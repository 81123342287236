import { jsx as _jsx } from "react/jsx-runtime";
import '@agentguru/foundations';
import './i18n/config';
import { trackers } from './configurations/trackers';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@agentguru/foundations';
import App from './App';
ReactDOM.createRoot(document.getElementById('root')).render(_jsx(React.StrictMode, { children: _jsx(App, {}) }));
trackers.init();
