var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as yup from 'yup';
var variables = {
    NODE_ENV: process.env.NODE_ENV,
    APP_STORAGE_KEY: process.env.APP_STORAGE_KEY,
    APP_DOMAIN_URL: process.env.APP_DOMAIN_URL,
    BFF_URL: process.env.BFF_URL,
    APP_LOG_ROCKET_ID: process.env.APP_LOG_ROCKET_ID,
    APP_BEAMER_ID: process.env.APP_BEAMER_ID,
    APP_SEGMENT_ID: process.env.APP_SEGMENT_ID,
    APP_GA_ID: process.env.APP_GA_ID,
    APP_URL: process.env.APP_URL,
    COCKPIT_URL: process.env.COCKPIT_URL,
    AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
    AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE,
};
var schema = yup.object({
    NODE_ENV: yup
        .string()
        .required()
        .oneOf(['development', 'production', 'test']),
    APP_STORAGE_KEY: yup.string().required(),
    APP_DOMAIN_URL: yup.string().required(),
    BFF_URL: yup.string().required(),
    APP_LOG_ROCKET_ID: yup.string(),
    APP_BEAMER_ID: yup.string(),
    APP_SEGMENT_ID: yup.string(),
    APP_GA_ID: yup.string(),
    COCKPIT_URL: yup.string().required(),
    APP_URL: yup.string().required(),
    AUTH0_DOMAIN: yup.string().required(),
    AUTH0_CLIENT_ID: yup.string().required(),
    AUTH0_AUDIENCE: yup.string().required(),
});
export var ENV = __assign({}, schema.validateSync(variables));
