import { useTranslation } from '@agentguru/i18n';
import { routes } from '../constants';
export function useNavBarRoutes() {
    var t = useTranslation().t;
    return [
        {
            key: 0,
            path: routes.goToAgencies(),
            icon: 'ag-agency',
            label: t('routes.agencies'),
            disabled: false,
            matches: ["/".concat(routes.agencies)],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 1,
            path: routes.goToBranches(),
            icon: 'ag-branch',
            label: t('routes.branches'),
            disabled: false,
            matches: ["/".concat(routes.branches)],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 2,
            path: routes.goToCommissions(),
            icon: 'ag-commission',
            label: t('routes.agency_commission'),
            disabled: false,
            matches: ["/".concat(routes.commissions)],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 3,
            path: routes.goToServiceFee(),
            icon: 'ag-payment',
            label: t('page.title.edit_service_fee'),
            disabled: false,
            matches: ["/".concat(routes.serviceFee)],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 4,
            path: routes.goToMarkups(),
            icon: 'ag-markup',
            label: t('routes.markup'),
            disabled: false,
            matches: ["/".concat(routes.markup)],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 5,
            path: routes.goToAirlineCommissions(),
            icon: 'ag-airline-commissions',
            label: t('routes.airlines_commissions'),
            disabled: false,
            matches: ["/".concat(routes.airlinesCommissions)],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 6,
            path: routes.goToSearchModules(),
            icon: 'ag-search-module',
            label: t('routes.search'),
            disabled: false,
            matches: ["/".concat(routes.searchModules)],
            isCustomRoute: false,
            subRoutes: [],
        },
    ];
}
