var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { graphQLClient } from '@/configurations/reactQuery';
import { trackers } from '@/configurations/trackers';
import { LoggedInDocument } from '@/graphql';
import { useAuth } from '@agentguru/auth';
import i18n from '@agentguru/i18n';
import { useQuery } from '@tanstack/react-query';
function fetcher(getIdTokenClaims) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var tokenClaims, language, loggedIn, id, email, first_name, last_name, agency;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getIdTokenClaims()];
                case 1:
                    tokenClaims = _b.sent();
                    language = tokenClaims === null || tokenClaims === void 0 ? void 0 : tokenClaims['user_metadata.language'];
                    i18n.changeLanguage(language);
                    return [4 /*yield*/, graphQLClient.request(LoggedInDocument, {})];
                case 2:
                    loggedIn = (_b.sent()).loggedIn;
                    id = loggedIn.id, email = loggedIn.email, first_name = loggedIn.first_name, last_name = loggedIn.last_name, agency = loggedIn.agency;
                    trackers.identify({
                        id: id,
                        email: email,
                        first_name: first_name,
                        last_name: last_name,
                        crm_company_id: agency === null || agency === void 0 ? void 0 : agency.crm_company_id,
                    });
                    return [2 /*return*/, __assign(__assign({}, loggedIn), { currency: (_a = tokenClaims['user_metadata.consolidator_currency']) !== null && _a !== void 0 ? _a : '' })];
            }
        });
    });
}
export function useMe() {
    var _a = useAuth(), isAuthenticated = _a.isAuthenticated, isLoading = _a.isLoading, user = _a.user, getIdTokenClaims = _a.getIdTokenClaims;
    var enabled = !isLoading && isAuthenticated;
    return useQuery(['Me'], function () { return fetcher(getIdTokenClaims); }, {
        enabled: enabled,
        refetchOnMount: false,
        staleTime: Infinity,
        placeholderData: {
            id: user === null || user === void 0 ? void 0 : user['.user_id'],
            username: user === null || user === void 0 ? void 0 : user.nickname,
            email: user === null || user === void 0 ? void 0 : user.email,
            first_name: user === null || user === void 0 ? void 0 : user.given_name,
            last_name: user === null || user === void 0 ? void 0 : user.family_name,
            phone_number: user === null || user === void 0 ? void 0 : user['.phone_number'],
            currency: user === null || user === void 0 ? void 0 : user['user_metadata.consolidator_currency'],
            language: user === null || user === void 0 ? void 0 : user['user_metadata.language'],
            avatar: {
                small_uri: user === null || user === void 0 ? void 0 : user['.avatar_small_uri'],
                medium_uri: user === null || user === void 0 ? void 0 : user['.avatar_medium_uri'],
                large_uri: user === null || user === void 0 ? void 0 : user['.avatar_large_uri'],
            },
            agency_id: user === null || user === void 0 ? void 0 : user['.agency_id'],
            agency: {
                crm_company_id: user === null || user === void 0 ? void 0 : user['.crm_company_id'],
                name: user === null || user === void 0 ? void 0 : user['.agency_name'],
                logo: {
                    small_uri: user === null || user === void 0 ? void 0 : user['.agency_small_uri'],
                },
            },
        },
    });
}
