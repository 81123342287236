import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingAuth, useAuth } from '@agentguru/auth';
import { CheckAppVersionProvider } from '@agentguru/components';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from './configurations/reactQuery';
import { AppBottomNavBarProvider } from './ui/layouts/AppBottomNavBar';
export function AppProviders(_a) {
    var children = _a.children;
    var _b = useAuth(), getAccessTokenSilently = _b.getAccessTokenSilently, isLoading = _b.isLoading;
    window.getAccessTokenSilently = function () {
        return getAccessTokenSilently({
            cacheMode: 'on',
        });
    };
    if (isLoading)
        return _jsx(LoadingAuth, {});
    return (_jsxs(QueryClientProvider, { client: queryClient, children: [_jsx(ReactQueryDevtools, { initialIsOpen: false }), _jsx(CheckAppVersionProvider, { children: _jsx(AppBottomNavBarProvider, { children: children }) })] }));
}
